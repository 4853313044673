import {
  LoggerFactoryOptions,
  LogGroupRule,
  LogLevel,
  LoggerFactory,
  LFService,
} from 'typescript-logging';

// Sending everything through our custom logger
const options = new LoggerFactoryOptions();
options.addLogGroupRule(new LogGroupRule(new RegExp('.+'), LogLevel.Debug));

// Using DEFAULT logger so package logs are also handled
LFService.DEFAULT.configure(options);
export const klogger: LoggerFactory = LFService.DEFAULT;
