import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { klogger } from '../common/koble-logger';
import {
  ApiEnvironment,
  ApiErrorCode,
  ApiService,
  AuthRole,
  AuthService,
  AuthToken
} from '@koble/koble-api-client';
import { LocalStorageService } from '../service/local-storage.service';

const logger = klogger.getLogger('AuthenticationService');

/* registering at root level to share single instance across the app */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isUserLoggedIn: boolean;

  constructor(
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    // Listen to isUserLoggedIn for user auth changes
    AuthService.instance.authStateChange$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (value === false) {
        this.localStorage.clear();
        this.router.navigate(['/auth/signin']);
      }
    });

    AuthService.instance.accessTokenRefreshed$.subscribe(token => {
      if (token) {
        this.localStorage.setValue('access_token', token);
      }
    });
  }

  async initialize() {
    logger.debug('AUTHSERVICE => initialize');
    ApiService.instance.init(
      ApiEnvironment.Production,
      this.localStorage.getValue('access_token') ?? undefined,
      this.localStorage.getValue('refresh_token') ?? undefined
    );
  }

  async adminSignIn(
    username: string,
    password: string
  ): Promise<string> {
    let errorMessage: string;
    try {
      const data: AuthToken = await AuthService.instance.signIn(
        username,
        password,
        AuthRole.Admin
      );

      // Store Access tokens
      const { accessToken, refreshToken } = data;
      if (accessToken && refreshToken) {
        this.localStorage.setValue('access_token', accessToken);
        this.localStorage.setValue('refresh_token', refreshToken);
      }

      // Navigate to Dashboard
      this.router.navigate(['/dashboard/home']);
    } catch (err) {
      logger.debug(`Authentication error ${err.code} ${err.message}`);
      if (Object.values(ApiErrorCode).includes(err.code)) {
        errorMessage = err.message;
      } else {
        // handles bad network and exceptional cases
        errorMessage =
          'Unable to sign you in, please check your network connection and try again';
      }
    }

    return errorMessage;
  }

  async signOut() {
    try {
      await AuthService.instance.signOut();
      this.localStorage.clear();
      this.router.navigate(['/auth/signin']);
    } catch (error) {
      logger.error('error signing out', error);
    }
  }
}
