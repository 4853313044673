import { Injectable } from '@angular/core';
import { klogger } from '../common/koble-logger';

const logger = klogger.getLogger('LocalStorageService');

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setValue(key: string, value: any) {
    if (value == null) {
      return;
    }

    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      logger.warn('Error storing value', e);
    }

  }

  getValue(key: string): any {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      logger.warn('Error fetching value', e);
      return null;
    }
  }

  clear() {
    localStorage.clear();
  }
}
