import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@koble/koble-api-client';
import { klogger } from '../common/koble-logger';
import { LocalStorageService } from '../service/local-storage.service';
import { AuthenticationService } from '../shared/auth.service';

const logger = klogger.getLogger('AuthGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private localStorage: LocalStorageService
  ) {
    this.authService.initialize();
  }

  async canActivate(
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ): Promise<boolean> {
    const isAuthenticated = await AuthService.instance.isAuthenticated();
    logger.debug(`Is user authenticated - ${isAuthenticated}`);
    if (isAuthenticated) {
      logger.debug(
        `User is Logged In => ${AuthService.instance.currentUserId}`
      );
      return true;
    } else {
      this.localStorage.clear();
      this.router.navigate(['/auth/signin']);
      return false;
    }
  }
}
